import { getUrlStyle } from '@chegg-wt/core/server/utils/redirect'
import { AppRouteCreators } from '@chegg-wt/core/types/core'

/**
 * These represent the major points in our application
 *
 * { href } -> indicates a link that will be handled by the browser, whether external or not
 * { href, as } -> indicates a link that will be handled by NextJS via it's SPA routing mechanisms
 */
export const routes: AppRouteCreators = {
  toCitationsHome: () => ({ href: '/' }),
  toProject: () => ({ href: '/project', as: '/project' }),
  toLogout: () => ({ href: '/logout', as: '/logout' }),
  toProfile: () => ({ href: '/myaccount', as: '/myaccount' }),
  /**
   * Returns WebRoute because there are issues with chegg auth
   */
  toUpgrade: () => ({ href: '/upgrade' }),
  toUpgradeCTA: () => ({ href: '/upgrade' }),
  /**
   * Returns WebRoute because there are issues with chegg auth
   */
  toSignup: () => ({ href: '/register' }),
  /**
   * Returns WebRoute because there are issues with chegg auth
   */
  toLogin: () => ({ href: '/login' }),
  toPlagiarism: () => ({ href: '/grammar-and-plagiarism/' }),
  toTermsAndConditions: () => ({ href: '/terms-and-conditions' }),
  toGateway: () => ({ href: '/gateway', as: '/gateway' }),
  toSourceTypeSelection: ({ style }) => {
    // if the path is to a style with a specific page built for SEO,
    // use that page instead of the page with the dynamic slug
    const styles = ['apa7', 'mla9', 'chicago']
    if (styles.includes(style)) {
      return {
        href: `/${getUrlStyle(style)}`,
        as: `/${getUrlStyle(style)}`,
      }
    }
    return { href: '/[style]', as: `/${getUrlStyle(style)}` }
  },
  toSourceSearch: ({ style, sourceType }) => {
    // if the path is to one of these pages (e.g. /apa/cite-a-book) that has a specific page built for SEO,
    // use that page instead of the page with the dynamic slug
    const styles = ['apa7', 'mla9']
    const sourceTypes = ['book', 'journal', 'website']
    if (styles.includes(style) && sourceTypes.includes(sourceType)) {
      return {
        href: `/${getUrlStyle(style)}/cite-a-${sourceType}`,
        as: `/${getUrlStyle(style)}/cite-a-${sourceType}`,
      }
    }

    return {
      href: '/[style]/[source]',
      as: `/${getUrlStyle(style)}/cite-a-${sourceType}`,
    }
  },
  toSourceSelection: ({ style, sourceType, query }) => ({
    href: `/[style]/[source]/search?q=${query}`,
    as: `/${getUrlStyle(style)}/cite-a-${sourceType}/search?q=${query}`,
  }),
  toSourceConfirmation: ({ style, sourceType }) => ({
    href: '/[style]/[source]/confirm',
    as: `/${getUrlStyle(style)}/cite-a-${sourceType}/confirm`,
  }),
  toNewCitation: ({ style, sourceType }) => ({
    href: '/[style]/[source]/new',
    as: `/${getUrlStyle(style)}/cite-a-${sourceType}/new`,
  }),
  toManualCitation: ({ style, sourceType }) => ({
    href: '/[style]/[source]/custom',
    as: `/${getUrlStyle(style)}/cite-a-${sourceType}/custom`,
  }),
  toEditCitation: ({ style, sourceType }) => ({
    href: '/[style]/[source]/edit',
    as: `/${getUrlStyle(style)}/cite-a-${sourceType}/edit`,
  }),
  toBibliography: ({ projectId }) => ({
    href: '/bibliographies/[id]',
    as: `/bibliographies/${projectId}`,
  }),
  toFolders: ({ create } = {}) => {
    if (create)
      return { href: `/folders?create=${create}`, as: `/folders?create=${create}` }
    return { href: '/folders', as: '/folders' }
  },
  toDashboard: () => ({ href: '/does/not/exist' }),
  toCitationsDashboard: () => ({
    href: '/does/not/exist',
  }),
  toCitationList: () => ({
    href: '/does/not/exist',
  }),
  toCitationListSource: () => ({
    href: '/does/not/exist',
  }),
  toCitationListForm: () => ({
    href: '/does/not/exist',
  }),
  toUploadLp: () => ({ href: '/does/not/exist' }),
  toPapersDashboard: () => ({
    href: '/does/not/exist',
  }),
  toWritingAssistant: ({ paperId } = {}) => {
    if (paperId)
      return { href: `/writing-assistant/[id]`, as: `/writing-assistant/${paperId}` }
    return { href: '/writing-assistant', as: '/writing-assistant' }
  },
  toError: () => ({
    href: '/does/not/exist',
  }),
}
