/* eslint-disable react/jsx-filename-extension */
import '@chegg-wt/core/theme/form.scss'
import '@chegg-wt/core/theme/_index.scss'

import NextApp from 'next/app'
import { withRouter } from 'next/router'
import React from 'react'
import MigrationNotificationBanner from '@chegg-wt/core/components/MigrationNotificationBanner'
import createStore from '@chegg-wt/core/state'
import { Provider } from 'react-redux'
import cookies from 'next-cookies'

import {
  shouldHideAds,
  getAdConfigVersion,
  getError,
} from '@chegg-wt/core/utils/adConfig'
import { getBackendOptsWithOptimizelySafe } from '@chegg-wt/core/utils/backendOpts'
import { getCurrentProjectPreloadedState } from '@chegg-wt/core/utils/currentProject'
import { trackWithoutThrow } from '@chegg-wt/core/utils/errors'
import { isServerSideContext } from '@chegg-wt/core/utils/isServerSideContext'
import { getActualStyleId } from '@chegg-wt/core/utils/styles'
import { getUserPreloadedState } from '@chegg-wt/core/utils/user'
import App from '@chegg-wt/core/components/App'
import CanonicalLink from '@chegg-wt/core/components/Head/CanonicalLink'
import DeIndex from '@chegg-wt/core/components/Head/DeIndex'
import { isNonCanonicalRoute } from '@chegg-wt/core/utils/url'
import { colors } from '@chegg-ui/theme'

import siteEnv from '../state/env'
import { navItemsSelector } from '../config/nav'
import { routes } from '../config/routes'
import { head } from '../config/head'
import { CMTheme } from '../theme'
import { exportTabIds } from '../config/exportTab'
import { footerLinkColumns } from '../copy/footerLinkColumns'
import { legalContents } from '../copy/legalContents'
import {
  isSourceExact,
  chooseGetSourceFromQuery,
  chooseGetStyleFromQuery,
  getCanonicalUrl,
} from '../utils/routes'

const makeStore = (preloadedState = {}) => {
  return createStore(routes, siteEnv, preloadedState)
}

class CitationsApp extends NextApp {
  // Note: this will disable automatic static optimization
  // see here: https://nextjs.org/docs#automatic-static-optimization
  // we want `getInitialProps` here to set query object when loaded on server
  //
  static async getInitialProps({ Component, ctx }) {
    const hideAds = shouldHideAds(ctx, routes)
    const adConfigVersion = getAdConfigVersion({ ctx, hideAds })
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {}
    const featureFlags = isServerSideContext(ctx)
      ? await getBackendOptsWithOptimizelySafe(ctx)
      : {}

    const { userRole } = cookies(ctx)
    const preloadedState = {
      featureFlags,
      user: getUserPreloadedState(userRole),
    }

    return { pageProps, hideAds, adConfigVersion, preloadedState }
  }

  componentDidMount() {
    const { adConfigVersion, hideAds } = this.props

    const error = getError(adConfigVersion, hideAds)
    if (error) {
      trackWithoutThrow(new Error(error))
    }
  }

  render() {
    const { Component, pageProps, preloadedState, router } = this.props
    const getSourceFromQuery = chooseGetSourceFromQuery(router)
    const getStyleFromQuery = chooseGetStyleFromQuery(router)

    const currentProject = getCurrentProjectPreloadedState({
      getStyleFromQuery,
      router,
      getActualStyleId,
    })
    const store = makeStore({ ...preloadedState, currentProject })
    const config = {
      head,
      navItemsSelector,
      routes,
      theme: CMTheme,
      exportTabIds,
      notificationBanner: MigrationNotificationBanner,
      footerContents: {
        footerLinkColumns,
        legalContents,
      },
      getSourceFromQuery,
      getStyleFromQuery,
      isSourceExact,
      getActualStyleId,
    }

    const getPageLayout = Component.getPageLayout || ((page) => page)

    const getSeoComponent = (router) => {
      const { route, query } = router
      const canonicalURL = getCanonicalUrl(route, query)
      const isNonCanonical = isNonCanonicalRoute(route)
      if (isNonCanonical) return <DeIndex />
      if (canonicalURL) return <CanonicalLink canonicalUrl={canonicalURL} />
    }

    const { route } = router
    const showBanner = route !== '/upgrade'
    const isHomePage = route === '/'

    return (
      <Provider store={store}>
        {getSeoComponent(this.props.router)}
        <App
          {...config}
          isHomePage={isHomePage}
          showBanner={showBanner}
          fullWidthBannerProps={{
            backgroundColour: colors.blue100,
            minHeight: '90px',
          }}
        >
          {getPageLayout(<Component {...pageProps} />, pageProps)}
        </App>
      </Provider>
    )
  }
}

export default withRouter(CitationsApp)
